import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Carousel from "react-elastic-carousel"
import ChildCategoryCard from "./child-category-card"
import CarouselArrow from "../../components/reusable-components/layout/carousel-arrow"

const ChildCategoriesCarousel = ({
  childCategories,
  location,
  title,
  className = "",
}) => {
  const breakPointsbasedOnProducts = [
    { width: 1, itemsToShow: 2 },
    { width: 320, itemsToShow: 3 },
    { width: 450, itemsToShow: 4 },
    { width: 580, itemsToShow: 5 },
    { width: 700, itemsToShow: 6 },
    { width: 830, itemsToShow: 7 },
    { width: 960, itemsToShow: 8 },
    { width: 1090, itemsToShow: 9 },
    { width: 1190, itemsToShow: 10 },
  ]

  const [categoryPathName, setCategoryPathName] = useState(
    location?.pathname || ""
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCategoryPathName(location.pathname)
    }
  }, [])

  let carouselMaxWidth = 0
  const slidesToShow =
    childCategories.length >= 10 ? 10 : childCategories.length
  const oneSlideWidth = 122

  for (let i = 0; i < slidesToShow; ++i) {
    carouselMaxWidth = carouselMaxWidth + oneSlideWidth
  }
  carouselMaxWidth = carouselMaxWidth + (slidesToShow - 1) * 10

  if (childCategories.length < 1) {
    return null
  }

  return (
    <ChildCategoriesPanelStyling>
      <StyledH2>{`${title} Subcategories`}</StyledH2>
      <StyledCarousel
        singleItem={slidesToShow == 1}
        carouselMaxWidth={carouselMaxWidth}
        className={className}
        pagination={false}
        showArrows={childCategories.length > 1}
        disableArrowsOnEnd
        renderArrow={CarouselArrow}
        breakPoints={breakPointsbasedOnProducts}
      >
        {childCategories.map((childCategory, childIndex) => {
          return (
            <ChildCategoryCard
              key={childIndex}
              childCategory={childCategory}
              categoryPathName={categoryPathName}
            />
          )
        })}
      </StyledCarousel>
    </ChildCategoriesPanelStyling>
  )
}

export default ChildCategoriesCarousel

// ===============
//     STYLES
// ===============
const StyledH2 = styled.h2`
  font-size: 1.333rem;
  font-weight: 700;
  margin: 0 20px 20px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin: 0 0 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 0 7px;
  }
`
const ChildCategoriesPanelStyling = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  margin-bottom: 20px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakMedium}) and (min-width: ${({
      theme,
    }) => theme.breakSmall}) {
    padding: 20px !important;
    margin: 0 30px 20px !important;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 10px !important;
    margin: 0 25px 20px !important;
  }
`
const StyledCarousel = styled(Carousel)`
  position: relative;
  width: 100%;
  .rec {
    outline: none;
  }
  .rec.rec-item-wrapper {
    ${({ singleItem }) => (!singleItem ? "" : `width: 120px !important;`)}
  }

  .rec-slider-container {
    max-width: ${({ theme }) => theme.maxWidth};
    width: ${({ carouselMaxWidth }) => carouselMaxWidth}px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      max-width: ${({ theme }) => theme.breakMedium};
      margin: 0 15px;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      max-width: ${({ theme }) => theme.breakMedium};
      margin: 0 auto;
    }
  }
  .rec-arrow {
    position: absolute;
    z-index: 1;
    top: 30%;
    height: 50px;
    width: 50px;
    padding: 0;
    color: ${({ theme }) => theme.colors.black1};
    background-color: ${({ theme }) => theme.colors.white}cc;
    transition: all 0.3s ease-in-out;
    border: none;
    border-radius: 100%;
    svg {
      margin-bottom: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      top: 5px;
    }
  }
  .rec-arrow:hover:enabled {
    line,
    path {
      transition: all 0.3s ease-in-out;
      stroke: white;
    }
    transition: all 0.3s ease-in-out;
    background-color: ${({ theme }) => theme.colors.grey};
  }
  .rec-arrow-left {
    left: -5vw;
    @media (max-width: 1400px) {
      left: -4vw;
    }
    @media (max-width: 1360px) {
      left: -1vw;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      left: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      left: 10px;
    }
  }
  .rec-arrow-right {
    svg {
      transform: scaleX(-1);
    }
    right: -5vw;
    @media (max-width: 1400px) {
      right: -4vw;
    }
    @media (max-width: 1360px) {
      right: -1vw;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      right: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      right: 10px;
    }
  }
  .child-category-data {
    height: 60px;
    margin-top: 0;
  }
`
