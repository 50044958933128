import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import { parseCurrency } from "../../lib/utils"

import ProductList from "./product-list"
import ProductsPagination from "./products-pagination"

const productCountPerLoad = 48

const ProductSortedList = ({
  products,
  productSectionsToLoad = 1,
  setProductSectionsToLoad = () => {},
}) => {
  // loop through the products and add a parsed price float
  for (let index = 0; index < products.length; index++) {
    const priceNumberParse = parseCurrency(products[index].price)
    const regPriceParse = parseCurrency(products[index].regularPrice)
    const discount = Math.floor(
      ((regPriceParse - priceNumberParse) / regPriceParse) * 100
    )
    products[index].priceNumber = priceNumberParse
    products[index].regularPriceNumber = regPriceParse
    products[index].discountPercentage = discount
  }

  const [sortType, setSortType] = useState("default")
  const [productsSorted, setProductsSorted] = useState(products)

  const topRef = useRef(null)

  // sort the product list if the sort setting or the product list changes
  useEffect(() => {
    const sortArray = (type) => {
      const types = {
        "high-first": { sortProperty: "priceNumber", sortDecreasing: true },
        "low-first": { sortProperty: "priceNumber", sortDecreasing: false },
        "sale-first": {
          sortProperty: "discountPercentage",
          sortDecreasing: true,
        },
      }
      if (typeof types[type] != "undefined") {
        const sortProperty = types[type].sortProperty
        const sortDirection = types[type].sortDecreasing ? 1 : -1
        const sorted = [...products].sort(
          (a, b) => sortDirection * (b[sortProperty] - a[sortProperty])
        )
        setProductsSorted(sorted)
      } else {
        setProductsSorted(products)
      }
    }
    sortArray(sortType)
  }, [sortType, products])

  // get shortened list of products that will be expanded by load more button
  const productsLoaded = productsSorted.slice(
    productCountPerLoad * (productSectionsToLoad - 1),
    productCountPerLoad * productSectionsToLoad
  )

  return (
    <>
      <ProductSortBoxStyling ref={topRef}>
        <StyledH2>Products</StyledH2>
        <StyledSelect onChange={(event) => setSortType(event.target.value)}>
          <option value="default">Sort by</option>
          <option value="high-first">Price high to low</option>
          <option value="low-first">Price low to high</option>
          <option value="sale-first">Biggest discount</option>
        </StyledSelect>
      </ProductSortBoxStyling>
      <ProductList
        products={productsLoaded}
        className="fullwidthcards"
        listId="shop_page"
      />
      {Math.ceil(productsSorted.length / productCountPerLoad) > 1 ? (
        <ProductsPagination
          totalProducts={productsSorted.length}
          currentPage={productSectionsToLoad}
          setCurrentPage={setProductSectionsToLoad}
          topRef={topRef}
          productCountPerLoad={productCountPerLoad}
        ></ProductsPagination>
      ) : null}
    </>
  )
}

export default ProductSortedList

// ===============
//     STYLES
// ===============
const ProductSortBoxStyling = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const StyledSelect = styled.select`
  width: 100%;
  padding: 1rem 20px;
  option {
    padding: 1rem 0;
  }
  border: 1px solid transparent;
  outline: none;
  background-color: ${({ theme }) => theme.colors.lightgrey2};
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    border-right-width: 20px;
    width: 300px;
  }
`
const StyledH2 = styled.h2`
  font-size: 1.777rem;
  font-weight: 700;
`
