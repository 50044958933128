import React, { useState } from "react"
import styled from "styled-components"

import ReactSlider from "react-slider"

import { formatPrice } from "../../lib/utils"

import CrossShape from "../reusable-components/cross-shape"

import FilterCheckbox from "./filter-checkbox"

const PriceFilterPanel = ({
  title,
  priceLimits,
  setPriceLimits,
  selectedPriceRange,
  setSelectedPriceRange,
  customFilters,
  toggleCustomFilterIdState,
}) => {
  const [isFiltersOpen, setFiltersOpen] = useState(false)
  return (
    <PriceFilterPanelStyling
      className={`filter ${isFiltersOpen ? "filter-open" : "filter-closed"}`}
    >
      <StyledH3 onClick={() => setFiltersOpen(!isFiltersOpen)}>
        {title}
        <CrossShape
          id="cross"
          className={`${isFiltersOpen ? "filter-open" : "filter-closed"} ${
            priceLimits[0] == selectedPriceRange[0] ? "grey" : "blue"
          } ${priceLimits[1] == selectedPriceRange[1] ? "grey" : "blue"}`}
        />
      </StyledH3>
      <FilterCheckbox
        className={`checkbox ${
          isFiltersOpen ? "show-checkbox" : "hide-checkbox"
        }`}
        key={"sale"}
        id={"sale"}
        checked={customFilters}
        toggleIdState={toggleCustomFilterIdState}
        text={"On Sale"}
      />
      {priceLimits[0] == priceLimits[1] ? (
        <div>{formatPrice({ price: priceLimits[1], cents: false })}</div>
      ) : (
        <StyledReactSlider
          className="horizontal-slider"
          thumbClassName="thumb-class"
          trackClassName="track-class"
          defaultValue={priceLimits}
          value={selectedPriceRange}
          min={priceLimits[0]}
          max={priceLimits[1]}
          onAfterChange={setSelectedPriceRange}
          pearling
          step={10}
          minDistance={100}
          renderThumb={({ key, className, ...props }, state) => {
            return (
              <span key={key}>
                <div key={`${key}-thumb`} className={className} {...props} />
                <PriceSliderFilterSelectedRangeStyling
                  key={`${key}-thumb-number`}
                  className={`price-range`}
                >
                  {`${formatPrice({
                    price: state.valueNow,
                    cents: false,
                  })}`}
                  {className.includes("thumb-class-0") ? <span>-</span> : null}
                </PriceSliderFilterSelectedRangeStyling>
              </span>
            )
          }}
        />
      )}
    </PriceFilterPanelStyling>
  )
}

export default PriceFilterPanel

// ===============
//     STYLES
// ===============
const StyledH3 = styled.h3`
  font-weight: 700;
  margin: 30px 0 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover span {
    opacity: 1;
  }
`
const StyledReactSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
  margin-bottom: 32px;
  .thumb-class {
    width: 15px;
    bottom: -2px;
    height: 15px;
    background: ${({ theme }) => theme.colors.blue};
    border-radius: 999px;
    outline: none;
    border: 2px solid white;
    cursor: grab;
  }
  .track-class {
    top: 0;
    bottom: 0;
    border-radius: 999px;
  }
  .track-class-0,
  .track-class-2 {
    background-color: ${({ theme }) => theme.colors.lightgrey3};
  }
  .track-class-1 {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`
const PriceSliderFilterSelectedRangeStyling = styled.div`
  margin-top: 20px;
  display: inline-block;
  span {
    padding: 0 5px;
  }
`
const PriceFilterPanelStyling = styled.div`
  &.filter-open .horizontal-slider {
    height: 10px;
    transition: 0.2s height ease-in-out, 0.2s margin ease-in-out;
  }
  &.filter-open .horizontal-slider .thumb-class {
    height: 15px;
    transition: 0.2s height ease-in-out;
  }
  &.filter-open .horizontal-slider .price-range {
    height: 32px;
    transition: 0.2s all ease-in-out;
  }
  &.filter-closed .horizontal-slider,
  &.filter-closed .horizontal-slider .thumb-class,
  &.filter-closed .horizontal-slider .price-range {
    height: 0;
    margin: 0;
    transition: 0.2s height ease-in-out, 0.2s margin ease-in-out;
    overflow: hidden;
  }
  .thumb-class {
    cursor: pointer;
  }

  .checkbox {
    margin-top: 10px;
    max-height: 40px;
    transition: 0.2s all ease-in-out;
    margin-bottom: 16px;
  }
  .hide-checkbox {
    max-height: 0px;
    transition: 0.2s all ease-in-out;
    margin-bottom: 0;
    overflow: hidden;
  }
`
