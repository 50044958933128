import React from "react"

const CrossShapeTiny = ({ fill, ...args }) => (
  <svg
    {...args}
    xmlns="http://www.w3.org/2000/svg"
    width="17.494"
    height="17.494"
    viewBox="0 0 17.494 17.494"
  >
    <g
      id="Icon_feather-x-circle"
      data-name="Icon feather-x-circle"
      transform="translate(0.5 0.5)"
    >
      <path
        id="Path_967"
        data-name="Path 967"
        d="M19.494,11.247A8.247,8.247,0,1,1,11.247,3a8.247,8.247,0,0,1,8.247,8.247Z"
        transform="translate(-3 -3)"
        fill="none"
        stroke={fill || "#03111f"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_968"
        data-name="Path 968"
        d="M18.448,13.5,13.5,18.448"
        transform="translate(-7.727 -7.727)"
        fill="none"
        stroke={fill || "#03111f"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_969"
        data-name="Path 969"
        d="M13.5,13.5l4.948,4.948"
        transform="translate(-7.727 -7.727)"
        fill="none"
        stroke={fill || "#03111f"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default CrossShapeTiny
