import React from "react"
import styled from "styled-components"

import CrossShapeTiny from "../../images/icons/cross-shape-tiny"

const ActiveFiltersBox = ({
  colourNodes,
  sizeNodes,
  brandNodes,
  finishNodes,
  materialNodes,
  filters,
  toggleNodeFilterIdState = () => {},
  saleFilter,
  toggleSaleFilter = () => {},
}) => {
  return (
    <FiltersBox>
      <StyledH2>Active Filters</StyledH2>
      <FilterItemsBox>
        {saleFilter ? (
          <FilterItem>
            On Sale
            <CrossShapeTiny onClick={() => toggleSaleFilter("sale")} />
          </FilterItem>
        ) : null}
        {Object.keys(filters["colours"]).map((colour, index) =>
          filters["colours"][colour] ? (
            <FilterItem key={`colour-active-filter-${index}`}>
              {colourNodes.map((node) =>
                node.databaseId === parseInt(colour) ? node.name : null
              )}
              <CrossShapeTiny
                onClick={() =>
                  toggleNodeFilterIdState("colours", parseInt(colour))
                }
              />
            </FilterItem>
          ) : null
        )}
        {Object.keys(filters["sizes"]).map((size, index) =>
          filters["sizes"][size] ? (
            <FilterItem key={`size-active-filter-${index}`}>
              {sizeNodes.map((node) =>
                node.databaseId === parseInt(size) ? node.name : null
              )}
              <CrossShapeTiny
                onClick={() => toggleNodeFilterIdState("sizes", parseInt(size))}
              />
            </FilterItem>
          ) : null
        )}
        {Object.keys(filters["finishes"]).map((finish, index) =>
          filters["finishes"][finish] ? (
            <FilterItem key={`size-active-filter-${index}`}>
              {finishNodes.map((node) =>
                node.databaseId === parseInt(finish) ? node.name : null
              )}
              <CrossShapeTiny
                onClick={() =>
                  toggleNodeFilterIdState("finishes", parseInt(finish))
                }
              />
            </FilterItem>
          ) : null
        )}
        {Object.keys(filters["materials"]).map((material, index) =>
          filters["materials"][material] ? (
            <FilterItem key={`size-active-filter-${index}`}>
              {materialNodes.map((node) =>
                node.databaseId === parseInt(material) ? node.name : null
              )}
              <CrossShapeTiny
                onClick={() =>
                  toggleNodeFilterIdState("materials", parseInt(material))
                }
              />
            </FilterItem>
          ) : null
        )}
        {Object.keys(filters["categories"]).map((category, index) =>
          filters["categories"][category] ? (
            <FilterItem key={`branc-active-filter-${index}`}>
              {brandNodes.map((node) =>
                node.databaseId === parseInt(category) ? node.name : null
              )}
              <CrossShapeTiny
                onClick={() =>
                  toggleNodeFilterIdState("categories", parseInt(category))
                }
              />
            </FilterItem>
          ) : null
        )}
      </FilterItemsBox>
    </FiltersBox>
  )
}

export default ActiveFiltersBox

const FiltersBox = styled.div`
  width: 100%;
  max-width: 934px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightgrey3};

  max-width: 934px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: 696px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    max-width: 100%;
  }

  margin-bottom: 20px;
`
const FilterItemsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
`
const FilterItem = styled.div`
  height: 31px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brandGrey};
  border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 16px;
  padding: 0 8px;
  svg {
    margin-left: 20px;
    cursor: pointer;
  }
`
const StyledH2 = styled.h2`
  font-size: 1.777rem;
  font-weight: 700;
`
