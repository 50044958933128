import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable-components/cloudflare-image"
import LinkWrapper from "../../components/reusable-components/link-wrapper"

import { getProperty } from "../../lib/utils"

const ChildCategoryCard = ({
  childCategory,
  categoryPathName,
  className = "",
}) => {
  const childCategoriesName = getProperty(childCategory, ["name"], "")
  const childCategoriesSlug = getProperty(childCategory, ["slug"], "")
  const childCategoriesImageFluid = getProperty(
    childCategory,
    ["image", "sourceUrl"],
    false
  )

  const link = `${categoryPathName}${childCategoriesSlug}`

  return (
    <ChildCategoryCardStying className={className} to={link}>
      {!childCategoriesImageFluid ? (
        <EmptyImageStyling />
      ) : (
        <StyledImg
          className={"full-width-inner-section"}
          objectFit="cover"
          src={childCategoriesImageFluid}
          alt="Carousel Image"
        />
      )}
      <ChildCategoryDataStyling className="child-category-data">
        <StyledH5>{childCategoriesName}</StyledH5>
      </ChildCategoryDataStyling>
    </ChildCategoryCardStying>
  )
}

export default ChildCategoryCard

// ===============
//     STYLES
// ===============
const StyledH5 = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black2};
  text-align: center;
`

const ChildCategoryCardStying = styled(LinkWrapper)`
  width: 112px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 90%;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 95%;
  }
  @media (max-width: 551px) {
    width: 90%;
  }
`
const ChildCategoryDataStyling = styled.div`
  :not(:first-child) {
  }
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white}bb;
  p {
    margin-top: 0;
    font-size: 0.88rem;
    text-align: center;
    font-weight: 400;
    line-height: 1.2rem;
  }
  height: 60px;
  margin-top: 0;
`
const EmptyImageStyling = styled.div`
  height: 112px;
`

const StyledImg = styled(CloudflareImage)`
  z-index: -1;
  height: 112px;
  img {
    left: unset;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  & div {
    padding-bottom: 100% !important;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    img {
      width: 100%;
    }
    &.full-width-inner-section {
      margin-bottom: 0rem;
    }
  }
`
