import React from "react"

import LinkWrapper from "../../components/reusable-components/link-wrapper"

import styled from "styled-components"

const CategoryLink = ({ link, text, count }) => (
  <StyledLinkWrapper to={link}>
    <span>{text}</span>
    {typeof count == "undefined" ? null : <span>{`(${count})`}</span>}
  </StyledLinkWrapper>
)

export default CategoryLink

const StyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
  span:nth-child(2) {
    padding-left: 0.2rem;
  }
`
