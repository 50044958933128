import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"

import LinkWrapper from "../../components/reusable-components/link-wrapper"
import CategoryLinksPanel from "../../components/products/category-links-panel"
import CheckboxFilterPanel from "../../components/products/checkbox-filter-panel"
import PriceFilterPanel from "../../components/products/price-filter-panel"
import ProductSortedList from "../../components/products/product-sorted-filter"
import CrossShape from "../../components/reusable-components/cross-shape"
import CategoryFooter from "../../components/products/category-footer"
import ChildCategoriesCarousel from "../../components/products/child-categories-carousel"

import { getProperty, parseCurrency, toCamel } from "../../lib/utils"
import ActiveFiltersBox from "../../components/products/active-filters-box"

const ShopPage = ({ pageContext, data, location }) => {
  const simpleProductsRaw = getProperty(
    data,
    ["allWpSimpleProduct", "nodes"],
    []
  )
  const variableProductsRaw = getProperty(
    data,
    ["allWpVariableProduct", "nodes"],
    []
  )

  // Build a structure for simple products and add missing attribute data
  const simpleProducts = simpleProductsRaw.map((pr) => ({
    ...pr,
    attributes: {
      nodes: [
        ...(pr?.allPaSize?.nodes || []),
        ...(pr?.allPaColour?.nodes || []),
        ...(pr?.allPaFinish?.nodes || []),
        ...(pr?.allPaMaterial?.nodes || []),
      ],
    },
  }))

  // Build a structure of variations and add missing data from variation-parent
  const variations = variableProductsRaw
    .map(
      (variableProduct) =>
        variableProduct?.variations?.nodes?.map((vr) => ({
          ...vr,
          productId: vr.variationId,
          slug: variableProduct?.slug,
          categories: variableProduct?.categories,
          productHoverImage:
            vr?.acf_product_information?.productHoverImage?.sourceUrl ||
            variableProduct?.acf_product_information?.productHoverImage
              ?.sourceUrl,
          attributes: {
            // This is absolutely horrible, but its the only way to get the missing attributes
            // of the variations in from the parent product
            nodes: [
              ...(vr?.attributes?.nodes || []),
              ...(
                variableProduct?.attributes?.nodes?.map((att) => {
                  if (!att?.variation)
                    return variableProduct[`${toCamel(att?.name)}s`]?.nodes
                }) || []
              ).flat(),
            ],
          },
        })) || []
    )
    ?.flat()

  const products = [...variations, ...simpleProducts]

  const childCategories = getProperty(data, ["childCategories", "nodes"], [])
  const colourNodes = getProperty(data, ["allWpPaColour", "nodes"], [])
  const sizeNodes = getProperty(data, ["allWpPaSize", "nodes"], [])
  const finishNodes = getProperty(data, ["allWpPaFinish", "nodes"], [])
  const materialNodes = getProperty(data, ["allWpPaMaterial", "nodes"], [])
  const allProductsLength = getProperty(pageContext, ["allProductsLength"], 0)
  const shopPagePathname = `/${process.env.GATSBY_SHOP_PAGE_SLUG}/`
  const shopPageName = getProperty(pageContext, ["shopPageName"], "")
  const pageCategoryId = getProperty(pageContext, ["databaseId"], "")
  const brandsCategoryId = getProperty(pageContext, ["brandsCategoryId"], "")
  const parentDatabaseId = getProperty(pageContext, ["parentDatabaseId"], "")
  const title = getProperty(data, ["wpProductCategory", "name"], shopPageName)
  const description = getProperty(
    data,
    ["wpProductCategory", "description"],
    ""
  )
  const allParentCategoryNameNodes = getProperty(
    data,
    ["allParentCategories", "nodes"],
    []
  )

  const categoryFooterTitle = getProperty(
    data,
    [
      "wpProductCategory",
      "acf_product_categories_and_brands",
      "categoryFooterTitle",
    ],
    ""
  )
  const categoryFooterBodyText = getProperty(
    data,
    [
      "wpProductCategory",
      "acf_product_categories_and_brands",
      "categoryFooterBodyText",
    ],
    ""
  )
  const footerButtonText = getProperty(
    data,
    [
      "wpProductCategory",
      "acf_product_categories_and_brands",
      "footerButtonText",
    ],
    ""
  )
  const footerButtonLink = getProperty(
    data,
    [
      "wpProductCategory",
      "acf_product_categories_and_brands",
      "footerButtonLink",
      "url",
    ],
    ""
  )
  const brandLogo = getProperty(
    data,
    [
      "wpProductCategory",
      "acf_product_categories_and_brands",
      "brandLogo",
      "localFile",
      "publicURL",
    ],
    null
  )
  const brandImageFluid = getProperty(
    data,
    ["wpProductCategory", "image", "sourceUrl"],
    false
  )
  const categoryPathnameMap = getProperty(
    pageContext,
    ["categoryPathnameMap"],
    {}
  )

  // check if the current page is a brand shop page
  const isBrandShopPage =
    parentDatabaseId && brandsCategoryId === parentDatabaseId

  // get brands if not on a brand shop page
  const brandNodes = getProperty(data, ["brandNodes", "nodes"], [])

  // test if you can do category lookups between the
  // different category maps
  const categoriesExist =
    allParentCategoryNameNodes &&
    categoryPathnameMap &&
    Object.keys(allParentCategoryNameNodes).length &&
    Object.keys(categoryPathnameMap).length

  // test if the brand and colour nodes have been populated
  const brandsExist = brandNodes && Object.keys(brandNodes).length
  const coloursExist = colourNodes && Object.keys(colourNodes).length
  const sizesExist = sizeNodes && Object.keys(sizeNodes).length
  const finishesExist = finishNodes && Object.keys(finishNodes).length
  const materialsExist = materialNodes && Object.keys(materialNodes).length

  const [isFiltersOpen, setFiltersOpen] = useState(true)
  const [priceLimits, setPriceLimits] = useState([0, 0])
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, 0])
  const [filteredProducts, setFilteredProducts] = useState(products)
  const [unfilteredBrands, setUnfilteredBrands] = useState(products)
  const [unfilteredColours, setUnfilteredColours] = useState(products)
  const [unfilteredSizes, setUnfilteredSizes] = useState(products)
  const [unfilteredFinishes, setUnfilteredFinishes] = useState(products)
  const [unfilteredMaterials, setUnfilteredMaterials] = useState(products)

  // create nodeFilters from grapqh nodes
  const [nodeFilters, setNodeFilters] = useState(() => {
    const queryFilter = getExistingActiveFilters(location)
    const nodeFiltersSourceData = {
      categories: brandsExist && brandNodes,
      colours: coloursExist && colourNodes,
      sizes: sizesExist && sizeNodes,
      finishes: finishesExist && finishNodes,
      materials: materialsExist && materialNodes,
    }
    const newNodeFilters = createNodeFiltersFromSourceData(
      nodeFiltersSourceData,
      queryFilter
    )
    return newNodeFilters
  })

  // create custom filters
  const [customFilters, setCustomFilters] = useState(() => {
    const queryFilter = getExistingActiveFilters(location)
    const newCustomFilters = {}

    // check if the query object from the URL (queryFilter) contains "sale=true"
    // if yes check the "sale" checkbox and again update the url

    newCustomFilters["sale"] = queryFilter.sale == "true" ? true : false
    return newCustomFilters
  })

  // Current page for pagination query parameter
  const [currentPage, setCurrentPage] = useState(() => {
    const queryFilter = getExistingActiveFilters(location)

    if (queryFilter.page) {
      return parseInt(queryFilter.page)
    } else {
      return 1
    }
  })

  // toggle the checkbox of the specified nodeFilter by updating the specified nodeFilter
  const toggleNodeFilterIdState = (filterName, idToUpdate, newState) => {
    setNodeFilters((oldFilters) => ({
      ...oldFilters,
      [filterName]: {
        ...oldFilters[filterName],
        [idToUpdate]:
          typeof newState == "undefined"
            ? !oldFilters[filterName][idToUpdate]
            : newState,
      },
    }))
    setCurrentPage(1)
  }

  useEffect(() => {
    const queryFilter = getExistingActiveFilters(location)

    if (queryFilter.page) {
      setCurrentPage(parseInt(queryFilter.page))
    } else {
      setCurrentPage(1)
    }
  }, [location])

  // toggle the checkbox of the called customFilter by updating the specified customFilter
  const toggleCustomFilterIdState = (filterName) => {
    setCustomFilters((oldFilters) => ({
      ...oldFilters,
      [filterName]: !oldFilters[filterName],
    }))
    setCurrentPage(1)
  }

  // Apply filters if they change
  useEffect(() => {
    // get products with prices in the price range filter
    const filteredPriceProducts = applyPriceRangeFilter(
      products,
      selectedPriceRange
    )

    // apply customFilters (e.g sale) : update the product list (& in the case of sale sort products)
    const filteredCustomProducts = applyCustomFilter(products, customFilters)

    // apply categories (brands) filter
    const filteredCategoryProducts = applyDiscreteNodeFilter(
      products,
      "categories",
      nodeFilters["categories"]
    )
    // apply sizes filter
    const filteredSizesProducts = applyDiscreteNodeFilter(
      products,
      "sizes",
      nodeFilters["sizes"]
    )
    // apply colours filter
    const filteredColoursProducts = applyDiscreteNodeFilter(
      products,
      "colours",
      nodeFilters["colours"]
    )
    // apply finishes filter
    const filteredFinishesProducts = applyDiscreteNodeFilter(
      products,
      "finishes",
      nodeFilters["finishes"]
    )
    // apply materials filter
    const filteredMaterialsProducts = applyDiscreteNodeFilter(
      products,
      "materials",
      nodeFilters["materials"]
    )

    // join all filtered products EXCEPT brands
    const unfilteredBrands = innerJoinProducts([
      filteredPriceProducts,
      filteredCustomProducts,
      filteredColoursProducts,
      filteredSizesProducts,
      filteredFinishesProducts,
      filteredMaterialsProducts,
    ])
    setUnfilteredBrands(unfilteredBrands)
    // join all filtered products EXCEPT colours
    const unfilteredColours = innerJoinProducts([
      filteredPriceProducts,
      filteredCustomProducts,
      filteredCategoryProducts,
      filteredSizesProducts,
      filteredFinishesProducts,
      filteredMaterialsProducts,
    ])
    setUnfilteredColours(unfilteredColours)
    // join all filtered products EXCEPT sizes
    const unfilteredSizes = innerJoinProducts([
      filteredPriceProducts,
      filteredCustomProducts,
      filteredColoursProducts,
      filteredCategoryProducts,
      filteredFinishesProducts,
      filteredMaterialsProducts,
    ])
    setUnfilteredSizes(unfilteredSizes)
    // join all filtered products EXCEPT finishes
    const unfilteredFinishes = innerJoinProducts([
      filteredPriceProducts,
      filteredCustomProducts,
      filteredColoursProducts,
      filteredCategoryProducts,
      filteredSizesProducts,
      filteredMaterialsProducts,
    ])
    setUnfilteredFinishes(unfilteredFinishes)
    // join all filtered products EXCEPT finishes
    const unfilteredMaterials = innerJoinProducts([
      filteredPriceProducts,
      filteredCustomProducts,
      filteredColoursProducts,
      filteredCategoryProducts,
      filteredSizesProducts,
      filteredFinishesProducts,
    ])
    setUnfilteredMaterials(unfilteredMaterials)

    // join all filtered products for the master list
    const completeFilteredProducts = innerJoinProducts([
      unfilteredBrands,
      unfilteredColours,
      unfilteredSizes,
    ])
    setFilteredProducts(completeFilteredProducts)

    // update UrlQueryString with node and custom filters
    updateUrlQueryString(nodeFilters, customFilters, location, currentPage)
  }, [nodeFilters, customFilters, selectedPriceRange, currentPage])

  // On mobile close FilterPanelBlockStyling on load
  useEffect(() => {
    if (typeof window != "undefined") {
      if (window.innerWidth < 1024) {
        setFiltersOpen(false)
      }
    }
  }, [])

  // calculate and set max and min price for price range
  useEffect(() => {
    // calculate price limits
    const newPriceLimits = calculatePriceRange(products)

    // set price limits
    setPriceLimits(newPriceLimits)

    // if the selectedMinPrice or selectedMaxPrice haven't been
    // set yet set them to the minimum and maximum possible
    if (!selectedPriceRange[0] || !selectedPriceRange[1]) {
      setSelectedPriceRange(newPriceLimits)
    }
  }, [])

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO
        title={getProperty(data, ["seaCategory", "seo", "title"], "")}
        location={location}
        yoast={getProperty(data, ["seaCategory", "seo"], {})}
      />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
      {childCategories.length < 1 ? null : (
        <ChildCategoriesCarousel
          childCategories={childCategories}
          location={location}
          title={title}
        />
      )}
      <ColumnsStyling>
        <FilterPanelStyling>
          {!categoriesExist ? null : (
            <CategoryLinksPanel
              title="Categories"
              pageCategoryId={pageCategoryId}
              shopPagePathname={shopPagePathname}
              shopPageName={shopPageName}
              allProductsLength={allProductsLength}
              allParentCategoryNameNodes={allParentCategoryNameNodes}
              categoryPathnameMap={categoryPathnameMap}
              isBrandShopPage={isBrandShopPage}
            />
          )}
          <FilterPanelBlockStyling
            className={`filter ${
              isFiltersOpen ? "filter-open" : "filter-closed"
            }`}
          >
            <StyledH2 onClick={() => setFiltersOpen(!isFiltersOpen)}>
              Filters
              <CrossShape
                id="cross"
                className={`${isFiltersOpen ? "filter-open" : "filter-closed"}`}
              />
            </StyledH2>
            <PriceFilterPanel
              title="Price"
              priceLimits={priceLimits}
              setPriceLimits={setPriceLimits}
              selectedPriceRange={selectedPriceRange}
              setSelectedPriceRange={(price) => {
                setSelectedPriceRange(price)
                setCurrentPage(1)
              }}
              customFilters={customFilters["sale"]}
              toggleCustomFilterIdState={() =>
                toggleCustomFilterIdState("sale")
              }
            />
            {!coloursExist ? null : (
              <CheckboxFilterPanel
                title="Colour"
                nodes={colourNodes}
                products={unfilteredColours}
                filterName="colours"
                filters={nodeFilters["colours"]}
                toggleNodeFilterIdState={toggleNodeFilterIdState}
              />
            )}
            {!sizesExist ? null : (
              <CheckboxFilterPanel
                title="Size"
                nodes={sizeNodes}
                products={unfilteredSizes}
                filterName="sizes"
                filters={nodeFilters["sizes"]}
                toggleNodeFilterIdState={toggleNodeFilterIdState}
              />
            )}
            {!finishesExist ? null : (
              <CheckboxFilterPanel
                title="Finish"
                nodes={finishNodes}
                products={unfilteredFinishes}
                filterName="finishes"
                filters={nodeFilters["finishes"]}
                toggleNodeFilterIdState={toggleNodeFilterIdState}
              />
            )}
            {!materialsExist ? null : (
              <CheckboxFilterPanel
                title="Material"
                nodes={materialNodes}
                products={unfilteredMaterials}
                filterName="materials"
                filters={nodeFilters["materials"]}
                toggleNodeFilterIdState={toggleNodeFilterIdState}
              />
            )}
            {!brandsExist ? null : (
              <CheckboxFilterPanel
                title="Brand"
                nodes={brandNodes}
                products={unfilteredBrands}
                filterName="categories"
                filters={nodeFilters["categories"]}
                toggleNodeFilterIdState={toggleNodeFilterIdState}
              />
            )}
          </FilterPanelBlockStyling>
        </FilterPanelStyling>
        <ProductsAreaStyling>
          {Object.keys(getExistingActiveFilters()).length > 0 ||
          customFilters["sale"] === true ? (
            <ActiveFiltersBox
              colourNodes={colourNodes}
              sizeNodes={sizeNodes}
              brandNodes={brandNodes}
              finishNodes={finishNodes}
              materialNodes={materialNodes}
              filters={nodeFilters}
              toggleNodeFilterIdState={toggleNodeFilterIdState}
              saleFilter={customFilters["sale"]}
              toggleSaleFilter={() => toggleCustomFilterIdState("sale")}
            ></ActiveFiltersBox>
          ) : null}
          {!products.length ? (
            <EmptyCategoryMessageStyling>
              {`There are currently no products in this category. `}
              {`Please visit our `}
              <LinkWrapper to={`/${process.env.GATSBY_SHOP_PAGE_SLUG}/`}>
                shop page
              </LinkWrapper>
              {` to see all of our available products.`}
            </EmptyCategoryMessageStyling>
          ) : !filteredProducts.length ? (
            <EmptyCategoryMessageStyling>
              {`There are currently no products in this category that match your filters.`}
            </EmptyCategoryMessageStyling>
          ) : (
            <ProductSortedList
              products={filteredProducts}
              productSectionsToLoad={currentPage}
              setProductSectionsToLoad={setCurrentPage}
            />
          )}
        </ProductsAreaStyling>
      </ColumnsStyling>
      {!(categoryFooterTitle && categoryFooterBodyText) ? null : (
        <CategoryFooter
          categoryFooterTitle={categoryFooterTitle}
          categoryFooterBodyText={categoryFooterBodyText}
          footerButtonText={footerButtonText}
          footerButtonLink={footerButtonLink}
          brandLogo={brandLogo}
          brandImageFluid={brandImageFluid}
        />
      )}
    </Layout>
  )
}

export default ShopPage

// ===============
//     HELPERS
// ===============
// loop through idList and return the ids that were set to true
const getActiveIds = (idList = {}) => {
  const activeIds = []
  for (const [id, isFilterActive] of Object.entries(idList)) {
    if (isFilterActive) {
      activeIds.push(id)
    }
  }
  return activeIds
}

// parse the query string and convert it into a filter
const getExistingActiveFilters = (location) => {
  // Check if any filters were stored in the URL
  const queryFilter = {}
  const queryString = !(location && location.search)
    ? ""
    : location.search.slice(1)
  const queryArray = queryString.split("&")
  // Parse querystring items
  for (let query_i = 0; query_i < queryArray.length; ++query_i) {
    const queryTuple = queryArray[query_i].split("=")
    if (queryTuple.length > 1) {
      const queryValues = queryTuple[1].split("+")
      queryFilter[queryTuple[0]] = []
      for (let val_i = 0; val_i < queryValues.length; ++val_i) {
        const val = queryValues[val_i]
        if (val) {
          queryFilter[queryTuple[0]].push(val)
        }
      }
    }
  }

  // Check if any filters were stored in localstorage
  let savedFilters = []
  if (typeof window != "undefined") {
    // Retrieve localStorage filters
    const savedFiltersString = window.localStorage.getItem("activeFilters")
    try {
      savedFilters = JSON.parse(savedFiltersString) || []
    } catch (e) {
      window.localStorage.removeItem("activeFilters")
    }

    // Merge localStorage filters in
    Object.keys(savedFilters).forEach((key) => {
      if (queryFilter[key]) {
        // add the value if it's not yet active
        if (key === "sale") {
          queryFilter[key] = savedFilters[key]
        } else {
          for (let i = 0; i < savedFilters[key].length; i++) {
            if (!queryFilter[key].includes(savedFilters[key][i])) {
              queryFilter[key].push(savedFilters[key][i])
            }
          }
        }
      } else {
        // add the filter because it's not yet used
        queryFilter[key] = savedFilters[key]
      }
    })
  }

  return queryFilter
}

// create a filter object from the supplied source data,
// then set parts of the filter to be active according to the query filter
const createNodeFiltersFromSourceData = (
  nodeFiltersSourceData,
  queryFilter
) => {
  const newNodeFilters = {}
  for (const [filterName, nodes] of Object.entries(nodeFiltersSourceData)) {
    if (nodes) {
      newNodeFilters[filterName] = {}
      for (let i = 0; i < nodes.length; ++i) {
        newNodeFilters[filterName][nodes[i].databaseId] = false
      }
      if (filterName in queryFilter) {
        const queryFilterSubArray = queryFilter[filterName]
        for (let arr_i = 0; arr_i < queryFilterSubArray.length; ++arr_i) {
          const queryFilterVal = queryFilterSubArray[arr_i]
          if (queryFilterVal in newNodeFilters[filterName]) {
            newNodeFilters[filterName][queryFilterVal] = true
          }
        }
      }
    }
  }
  return newNodeFilters
}

// update the URL to reflect the active filters
const updateUrlQueryString = (
  nodeFilters,
  customFilters,
  location,
  currentPage
) => {
  const queryStringArray = []
  const localStorageObject = {}

  // loop through the list of nodeFilters and update URL
  for (const [filterName, idList] of Object.entries(nodeFilters)) {
    const activeIds = getActiveIds(idList)
    if (activeIds.length) {
      localStorageObject[filterName] = activeIds
      queryStringArray.push(`${filterName}=${activeIds.join("+")}`)
    }
  }
  // loop through the customFilters and update URL
  for (const [customFilterName] of Object.entries(customFilters)) {
    if (customFilters[customFilterName] == true) {
      localStorageObject[customFilterName] = "true"
      queryStringArray.push(`${customFilterName}=true`)
    }
  }

  if (currentPage != 1) {
    queryStringArray.push(`page=${currentPage}`)
  }

  // Store the new selection of filters to localstorage
  window.localStorage.setItem(
    "activeFilters",
    JSON.stringify(localStorageObject)
  )

  // if the queryStringArray created above is not empty add the new filters
  const newQueryString = !queryStringArray.length
    ? ""
    : `?${queryStringArray.join("&")}`

  if (location && location.pathname) {
    const oldPathname = location.pathname
    const newPath = `${oldPathname}${newQueryString}`
    history.replaceState(null, "", newPath)
  }
}

// apply all of the supplied discrete nodeFilters
const applyDiscreteNodeFilter = (inFilteredProducts, filterName, idList) => {
  // loop through the list of nodeFilters and apply each to
  // the inFilteredProducts list
  const activeIds = getActiveIds(idList)
  if (activeIds.length) {
    const newFilteredList = []
    for (let prod_i = 0; prod_i < inFilteredProducts.length; prod_i++) {
      const productToTest = inFilteredProducts[prod_i]

      // Either check attributes or categories
      let nodeArrayToTest = productToTest?.attributes?.nodes || []
      if (filterName == "categories") {
        nodeArrayToTest = productToTest?.categories?.nodes || []
      }

      // loop through the nodes of the property being filtered
      for (let node_i = 0; node_i < nodeArrayToTest.length; node_i++) {
        // if the filter is applicable to the current node add the product
        // to the output list of filtered products
        for (let id_i = 0; id_i < activeIds.length; ++id_i) {
          if (
            activeIds[id_i] == nodeArrayToTest[node_i]?.attributeId ||
            activeIds[id_i] == nodeArrayToTest[node_i]?.databaseId
          ) {
            newFilteredList.push(productToTest)
            break
          }
        }
      }
    }
    return newFilteredList
  }
  return [...inFilteredProducts]
}

// filter and sort products according to custom filter
const applyCustomFilter = (inFilteredProducts, customFilters) => {
  let outFilteredProducts = []
  // for the sale custom filter, filter and sort products below
  if (customFilters["sale"]) {
    for (let prod_i = 0; prod_i < inFilteredProducts.length; prod_i++) {
      const productToTest = inFilteredProducts[prod_i]

      // if the product onSale attribute is true add the product to the new product array
      if (productToTest.onSale) {
        outFilteredProducts.push(productToTest)
      }
    }

    // sort products according to discountPercentage
    return outFilteredProducts.sort(
      (a, b) => b["discountPercentage"] - a["discountPercentage"]
    )
  }

  return [...inFilteredProducts]
}

// Merge the datasets with items that are present in all datasets
const innerJoinProducts = (productSets) => {
  let combinedProducts = [...productSets[0]]
  // Go through each dataset
  for (let i = 1; i < productSets.length; i++) {
    // If any of the products in combinedProducts are missing from the next dataset, remove it
    combinedProducts = combinedProducts?.filter((pr) =>
      productSets[i].some((spr) => spr.productId === pr.productId)
    )
  }
  return combinedProducts
}

//calculate min and max prices for an array of products
const calculatePriceRange = (products) => {
  let tempMinPrice = 0
  let tempMaxPrice = 0

  // loop through products and find the max and min
  // prices of the list
  for (let i = 0; i < products.length; ++i) {
    const price = parseCurrency(products[i].price)
    if (!Number.isNaN(price) && price) {
      if (!tempMinPrice || price < tempMinPrice) {
        tempMinPrice = price
      }
      if (!tempMaxPrice || price > tempMaxPrice) {
        tempMaxPrice = price
      }
    }
  }
  // prevent possible rounding errors
  tempMinPrice = Math.floor(tempMinPrice / 10) * 10
  tempMaxPrice = Math.ceil(tempMaxPrice / 10) * 10

  return [tempMinPrice, tempMaxPrice]
}

// filter out products with prices outside of the selected price range
const applyPriceRangeFilter = (products, selectedPriceRange) => {
  let newFilteredProducts = []
  const selectedMinPrice = selectedPriceRange[0]
  const selectedMaxPrice = selectedPriceRange[1]

  // first loop through products and get a list of products
  // that fall into the selected price range (filter)
  for (let product_i = 0; product_i < products.length; product_i++) {
    const price = parseCurrency(products[product_i].price)
    if (selectedMinPrice <= price && price <= selectedMaxPrice) {
      newFilteredProducts.push(products[product_i])
    }
  }
  return newFilteredProducts
}

// ===============
//     STYLES
// ===============
const EmptyCategoryMessageStyling = styled.div`
  a {
    font-weight: bold;
  }
`
const FilterPanelStyling = styled.div`
  width: calc(100% - 950px);
  margin-right: 20px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: calc(100% - 710px);
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
  }
`
const FilterPanelBlockStyling = styled.div`
  padding: 16px 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  margin: 0 0 20px;
  overflow: hidden;
  & > div {
    transition: 0.2s all ease-in-out;
    overflow: hidden;
  }
  &.filter-closed > div {
    margin: 0;
    transition: 0.2s all ease-in-out;
    border: 0px solid white;
  }
  & > div h3 {
    height: 35px;
    transition: 0.2s all ease-in-out;
    overflow: hidden;
  }
  &.filter-closed > div h3,
  &.filter-closed > div div,
  &.filter-closed > div a,
  &.filter-closed > div .horizontal-slider {
    height: 0;
    margin: 0;
    transition: 0.2s all ease-in-out;
    border: 0px solid white;
  }
`
const ProductsAreaStyling = styled.div`
  width: 950px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 710px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
  }
`
const ColumnsStyling = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const StyledH2 = styled.h2`
  font-size: 1.777rem;
  font-weight: 700;
  margin: 7px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover span {
    opacity: 1;
  }
`

const StyledDescription = styled.p`
  margin-bottom: 30px;
`

// ===============
//      QUERY
// ===============
export const shopQueryData = graphql`
  query GET_SHOP_PAGE_QUERY(
    $databaseId: Int!
    $brandsCategoryId: Int!
    $allParentCategoryIds: [Int]
    $categoryIds: [Int]
  ) {
    wpProductCategory(databaseId: { eq: $databaseId }) {
      name
      description
      image {
        sourceUrl
      }
      acf_product_categories_and_brands {
        brandLogo {
          localFile {
            publicURL
          }
        }
        categoryFooterBodyText
        categoryFooterTitle
        footerButtonText
        footerButtonLink {
          url
        }
      }
    }
    allWpPaColour(sort: { fields: name, order: ASC }) {
      nodes {
        databaseId
        name
        slug
      }
    }
    allWpPaSize(sort: { fields: name, order: ASC }) {
      nodes {
        databaseId
        name
        slug
      }
    }
    allWpPaFinish(sort: { fields: name, order: ASC }) {
      nodes {
        databaseId
        name
        slug
      }
    }
    allWpPaMaterial(sort: { fields: name, order: ASC }) {
      nodes {
        slug
        databaseId
        name
      }
    }
    childCategories: allWpProductCategory(
      sort: { fields: name, order: ASC }
      filter: { parentDatabaseId: { eq: $databaseId }, slug: { ne: "brands" } }
    ) {
      nodes {
        databaseId
        name
        slug
        image {
          sourceUrl
        }
      }
    }
    allParentCategories: allWpProductCategory(
      sort: { fields: name, order: ASC }
      filter: {
        databaseId: { in: $allParentCategoryIds }
        slug: { ne: "brands" }
      }
    ) {
      nodes {
        databaseId
        name
        slug
      }
    }
    brandNodes: allWpProductCategory(
      sort: { fields: name, order: ASC }
      filter: { parentDatabaseId: { eq: $brandsCategoryId } }
    ) {
      nodes {
        databaseId
        name
        slug
      }
    }
    allWpSimpleProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { databaseId: { in: $categoryIds } } }
        }
      }
    ) {
      nodes {
        name
        productId: databaseId
        shortDescription
        price
        regularPrice
        onSale
        averageRating
        sku
        slug
        image {
          sourceUrl
        }
        acf_product_information {
          productHoverImage {
            sourceUrl
          }
        }
        categories: productCategories {
          nodes {
            slug
            databaseId
          }
        }
        # Simple products cannot properly query attributes using the "attributes" field
        # So we have to query the attributes seperately
        allPaColour {
          nodes {
            attributeId: databaseId
          }
        }
        allPaSize {
          nodes {
            attributeId: databaseId
          }
        }
        allPaFinish {
          nodes {
            attributeId: databaseId
          }
        }
        allPaMaterial {
          nodes {
            attributeId: databaseId
          }
        }
        acf_product_tile_add_ons {
          squareMetresOfTilesPerBox
        }
      }
    }
    allWpVariableProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { databaseId: { in: $categoryIds } } }
        }
      }
    ) {
      nodes {
        name
        productId: databaseId
        shortDescription
        price
        regularPrice
        onSale
        averageRating
        sku
        slug
        image {
          sourceUrl
        }
        acf_product_information {
          productHoverImage {
            sourceUrl
          }
        }
        categories: productCategories {
          nodes {
            slug
            databaseId
          }
        }
        # Due to the weird way wpgraphql returns this data, we have to use
        # "attributes" field to check if the attribute is used for variations,
        # but the "pa_att" field to actually get the ID
        attributes {
          nodes {
            name
            variation
          }
        }
        allPaColour {
          nodes {
            attributeId: databaseId
          }
        }
        allPaSize {
          nodes {
            attributeId: databaseId
          }
        }
        allPaFinish {
          nodes {
            attributeId: databaseId
          }
        }
        allPaMaterial {
          nodes {
            attributeId: databaseId
          }
        }
        acf_product_tile_add_ons {
          squareMetresOfTilesPerBox
        }
        variations {
          nodes {
            variationId: databaseId
            name
            onSale
            price
            regularPrice
            sku
            # Variations cannot query seperate attributes ("paSize, paColour")
            # But they can query attributes using the "attributes" field
            attributes {
              nodes {
                attributeId
                value
              }
            }
            image {
              sourceUrl
            }
            acf_product_information {
              productHoverImage {
                sourceUrl
              }
            }
            acf_product_tile_add_ons {
              squareMetresOfTilesPerBox
            }
          }
        }
      }
    }
    seaCategory(database_id: { eq: $databaseId }) {
      seo {
        description
        meta_description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
