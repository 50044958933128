import React from "react"
import { useEffect } from "react"
import styled from "styled-components"

import ProductCard from "../../components/products/product-card"
import FlexSection from "../../components/reusable-components/layout/flex-section"
import { analyticsViewItemList } from "../../services/google-analytics"

const ProductList = ({
  products,
  className = "",
  columnNumber = 4,
  margin = "0 auto 6rem",
  listId = "",
}) => {
  useEffect(() => {
    analyticsViewItemList(products, listId)
  }, [products])

  return (
    <ProductListFlexStyling
      className={className}
      direction="row"
      margin={margin}
    >
      {products.map((product, productIndex) => {
        return (
          <ProductCardStyling
            key={productIndex}
            columnNumber={columnNumber < 2 ? 2 : columnNumber}
          >
            <ProductCard product={product} className={className} width="100%" />
          </ProductCardStyling>
        )
      })}
    </ProductListFlexStyling>
  )
}

export default ProductList

// ===============
//     STYLES
// ===============
const ProductListFlexStyling = styled(FlexSection)`
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    justify-content: space-between;
  }
`

const ProductCardStyling = styled.div`
  width: calc(
    calc(
        100% -
          ${({ columnNumber }) => {
            return 10 * (columnNumber - 1)
          }}px
      ) / ${({ columnNumber }) => columnNumber}
  );
  overflow: hidden;
  margin-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.breakLarge}) {
    :not(
        :nth-child(
            ${({ columnNumber }) => columnNumber}n
              + ${({ columnNumber }) => columnNumber}
          )
      ) {
      margin-right: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: calc(
      calc(
          100% -
            ${({ columnNumber }) => {
              return 10 * (columnNumber - 2)
            }}px
        ) / ${({ columnNumber }) => columnNumber - 1}
    );
    :not(
        :nth-child(
            ${({ columnNumber }) => columnNumber - 1}n
              + ${({ columnNumber }) => columnNumber - 1}
          )
      ) {
      margin-right: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: calc(calc(100% - calc(10px * 1)) / 2);
    :not(
        :nth-child(
            ${({ columnNumber }) => columnNumber - 1}n
              + ${({ columnNumber }) => columnNumber - 1}
          )
      ) {
      margin-right: 0;
    }
    :not(:nth-child(2n + 2)) {
      margin-right: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: calc(50% - 10px);
  }
`
