import React, { useState } from "react"
import styled from "styled-components"

import FilterCheckbox from "./filter-checkbox"

import CrossShape from "../reusable-components/cross-shape"

const CheckboxFilterPanel = ({
  title,
  nodes,
  productCountMap,
  products,
  filterName = "",
  filters = {},
  toggleNodeFilterIdState = () => {},
}) => {
  const [isFiltersOpen, setFiltersOpen] = useState(false)

  // test if any of the entries in the object is true
  let isPanelfiltered = false
  for (const [key, value] of Object.entries(filters)) {
    if (value) {
      isPanelfiltered = true
    }
  }

  // Prepare filter checkboxes
  const checkboxes =
    nodes
      ?.map((node) => {
        const id = node.databaseId
        const name = node.name

        // Hide unused nodes
        if (!name) {
          if (filters[id]) {
            toggleNodeFilterIdState(filterName, id, false)
          }
          return null
        }

        // Either use all products to calculate ocunt or use the incoming count-map
        let productCount = 0
        if (Array.isArray(products)) {
          productCount = products.reduce((acc, curr) => {
            if (filterName == "categories") {
              const prCategories = curr?.categories?.nodes || []
              return prCategories?.some((it) => id == it?.databaseId)
                ? acc + 1
                : acc
            } else {
              const prAttributes = curr?.attributes?.nodes || []
              return prAttributes?.some((it) => id == it?.attributeId)
                ? acc + 1
                : acc
            }
          }, 0)
        } else {
          productCount = productCountMap[id]
        }

        // Hide empty nodes
        if (productCount < 1) {
          if (filters[id]) {
            toggleNodeFilterIdState(filterName, id, false)
          }
          return null
        }

        return (
          <FilterCheckbox
            className="filter-checkbox"
            key={id}
            id={id}
            checked={filters[id]}
            toggleIdState={(id) => toggleNodeFilterIdState(filterName, id)}
            text={name}
            count={productCount}
          />
        )
      })
      ?.filter((it) => !!it) ?? []

  return (
    <CheckboxFilterPanelStyling
      className={`filter ${isFiltersOpen ? "filter-open" : "filter-closed"}`}
    >
      <StyledLabel onClick={() => setFiltersOpen(!isFiltersOpen)}>
        <StyledH3>{title}</StyledH3>
        <CrossShape
          id="cross"
          className={`${isFiltersOpen ? "filter-open" : "filter-closed"} ${
            isPanelfiltered ? "blue" : "grey"
          }`}
        />
      </StyledLabel>
      {checkboxes?.length > 0 ? (
        checkboxes
      ) : (
        <p>
          There are no {filterName} to select for the current set of products
        </p>
      )}
    </CheckboxFilterPanelStyling>
  )
}

export default CheckboxFilterPanel

// ===============
//     STYLES
// ===============
const StyledLabel = styled.div`
  font-weight: 700;
  margin: 30px 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover span {
    opacity: 1;
  }
`
const StyledH3 = styled.h3`
  font-weight: 700;
`
const CheckboxFilterPanelStyling = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  overflow: hidden;
  & > div.filter-checkbox {
    height: 32px;
    overflow: hidden;
    transition: 0.2s all ease-in-out;
  }
  &.filter-closed > div.filter-checkbox {
    height: 0;
    margin: 0;
    transition: 0.2s all ease-in-out;
  }
  p {
    font-size: 12px;
  }
`
