import React from "react"
import styled from "styled-components"

import CloudflareImage from "../reusable-components/cloudflare-image"
import { ReactSVG } from "react-svg"

import LinkButton from "../reusable-components/buttons/link-button"
import FlexSection from "../reusable-components/layout/flex-section"

const CategoryFooter = ({
  brandImageFluid,
  categoryFooterTitle,
  categoryFooterBodyText,
  footerButtonText,
  footerButtonLink,
  brandLogo,
}) => {
  return (
    <FlexSection direction="row" margin="6rem 0 0">
      <CategoryFooterColumnStyling
        className={"full-width-inner-section"}
        margin="0 4rem 0 0"
      >
        {!brandLogo ? null : <ReactSVG src={brandLogo} />}
        {!brandLogo ? (
          <StyledH4Big
            dangerouslySetInnerHTML={{ __html: categoryFooterTitle }}
          />
        ) : (
          <StyledH4 dangerouslySetInnerHTML={{ __html: categoryFooterTitle }} />
        )}
        <p dangerouslySetInnerHTML={{ __html: categoryFooterBodyText }} />
        {!(footerButtonText && footerButtonLink) ? null : (
          <StyledLinkButton
            to={footerButtonLink}
            text={footerButtonText}
            className="categoryFooterBtn"
          />
        )}
      </CategoryFooterColumnStyling>
      {!brandImageFluid ? null : (
        <StyledImg
          className={"full-width-inner-section"}
          objectFit="cover"
          objectPosition="0 60%"
          width="620px"
          src={brandImageFluid}
        />
      )}
    </FlexSection>
  )
}

export default CategoryFooter

// ===============
//     STYLES
// ===============
const StyledImg = styled(CloudflareImage)`
  width: 50%;
  align-self: stretch;
  img {
    left: unset;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    & div {
      padding-bottom: 0 !important;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    &.full-width-inner-section {
      margin-bottom: 0rem;
    }
  }
`
const StyledH4 = styled.h4`
  margin-top: 10px;
  font-size: 1.7rem;
  font-weight: 400;
`
const StyledH4Big = styled.h4`
  margin-top: 10px;
  font-size: 2.369rem;
  font-weight: 600;
`
const CategoryFooterColumnStyling = styled(FlexSection)`
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    &.full-width-inner-section {
      margin-bottom: 0rem;
    }
  }
  p {
    font-size: 1.3rem;
    margin-bottom: 3rem;
  }
`

const StyledLinkButton = styled(LinkButton)`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    &.categoryFooterBtn {
      margin-bottom: 4rem;
    }
  }
`
