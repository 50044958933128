import React, { useState, useEffect } from "react"
import styled from "styled-components"

import CategoryLink from "./category-link"
import CrossShape from "../reusable-components/cross-shape"

const CategoryLinksPanel = ({
  title,
  pageCategoryId,
  shopPagePathname,
  shopPageName,
  allProductsLength,
  allParentCategoryNameNodes,
  categoryPathnameMap,
  products,
  isBrandShopPage,
}) => {
  const [isFiltersOpen, setFiltersOpen] = useState(() => {
    if (
      typeof window != "undefined" &&
      window.innerWidth >= 1024 &&
      window.location.pathname.includes(process.env.GATSBY_BRANDS_PARENT_SLUG)
    ) {
      return true
    } else {
      return false
    }
  })

  return (
    <CategoryLinksPanelStyling
      className={`filter ${isFiltersOpen ? "filter-open" : "filter-closed"}`}
    >
      <StyledH2 onClick={() => setFiltersOpen(!isFiltersOpen)}>
        {title}
        <CrossShape
          id="cross"
          className={`${isFiltersOpen ? "filter-open" : "filter-closed"}`}
        />
      </StyledH2>
      {!pageCategoryId ? null : (
        <CategoryLink
          link={`${shopPagePathname}${
            !isBrandShopPage ? `` : `?brands=${pageCategoryId}`
          }`}
          text={shopPageName}
        />
      )}
      {allParentCategoryNameNodes.map((node) => {
        const categoryId = node.databaseId
        const name = node.name
        const link = `${categoryPathnameMap[categoryId]}${
          !isBrandShopPage ? `` : `?brands=${pageCategoryId}`
        }`

        // Either use all products or use the incoming map
        let productCount = 0
        if (Array.isArray(products)) {
          productCount = products.reduce(
            (acc, curr) =>
              curr["categories"]?.nodes.some(
                (it) => it.databaseId == categoryId
              )
                ? acc + 1
                : acc,
            0
          )
        }

        if (
          !name ||
          !link ||
          typeof link == "undefined" ||
          categoryId === pageCategoryId
        ) {
          return null
        }
        return <CategoryLink key={link} link={link} text={name} />
      })}
    </CategoryLinksPanelStyling>
  )
}

export default CategoryLinksPanel

// ===============
//     STYLES
// ===============
const StyledH2 = styled.h2`
  font-size: 1.777rem;
  font-weight: 700;
  margin: 7px 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover span {
    opacity: 1;
  }
`
const CategoryLinksPanelStyling = styled.div`
  padding: 16px 20px 0px;
  border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  margin: 0 0 20px;
  overflow: hidden;
  & > a {
    height: 32px;
    transition: 0.2s all ease-in-out;
    overflow: hidden;
  }
  &.filter-closed > a {
    height: 0;
    margin: 0;
    transition: 0.2s all ease-in-out;
  }
`
