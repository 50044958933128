import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import Arrow from "../../images/icons/slider-arrow"

const ProductsPagination = ({
  currentPage,
  totalProducts,
  setCurrentPage = () => {},
  topRef = null,
  productCountPerLoad,
}) => {
  const [paginationNumbersDisplayed, setPaginationNumbersDisplayed] = useState([
    1,
  ])

  // test if there are more products to load by a Load More button
  const canLoadMore = productCountPerLoad * currentPage < totalProducts

  const numberOfPages = Math.ceil(totalProducts / productCountPerLoad)

  useEffect(() => {
    let numbers = [1]

    if (currentPage > 3) {
      numbers.push("...")
    }

    for (var i = currentPage - 1; i < currentPage + 2; i++) {
      if (!numbers.includes(i) && i > 1 && i < numberOfPages) {
        numbers.push(i)
      }
    }

    if (currentPage < numberOfPages - 2) {
      numbers.push("...")
    }

    if (!numbers.includes(numberOfPages)) {
      numbers.push(numberOfPages)
    }

    setPaginationNumbersDisplayed(numbers)
  }, [currentPage, totalProducts])

  return (
    <PaginationBar>
      <PaginationButton
        disabled={currentPage <= 1}
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
            topRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        }}
      >
        <Arrow className="arrow left"></Arrow>
      </PaginationButton>
      <PaginationNumbersSection>
        {paginationNumbersDisplayed.map((number, index) => (
          <PaginationNumber
            key={index}
            className={number === currentPage ? "selected" : ""}
            onClick={() => {
              if (!isNaN(number)) {
                setCurrentPage(number)
                topRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            }}
          >
            {number}
          </PaginationNumber>
        ))}
      </PaginationNumbersSection>
      <PaginationButton
        onClick={() => {
          if (canLoadMore) {
            setCurrentPage(currentPage + 1)
            topRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        }}
        disabled={!canLoadMore}
      >
        <Arrow className="arrow right"></Arrow>
      </PaginationButton>
    </PaginationBar>
  )
}

export default ProductsPagination

// ===============
//     STYLES
// ===============

const PaginationBar = styled.div`
  height: 60px;
  width: 100%;
  background-color: white;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.lightgrey3};
  display: flex;
  justify-content: space-between;
`
const PaginationButton = styled.div`
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  &:disabled {
    color: grey;
  }

  .arrow {
    margin: 0 15px;
    &.right {
      transform: rotate(180deg);
      &:hover {
        margin-right: 5px;
        margin-left: 15px;
      }
    }

    transition: margin 0.3s ease-out;
  }

  &:hover {
    .arrow.right {
      margin-right: 10px;
      margin-left: 20px;
    }

    .arrow.left {
      margin-left: 10px;
      margin-right: 20px;
    }
  }
`
const PaginationNumbersSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const PaginationNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  margin: 1px;
  cursor: pointer;

  &.selected {
    font-weight: bold;
  }
`
