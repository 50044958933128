import React from "react"
import styled from "styled-components"

const FilterCheckbox = ({
  id,
  toggleIdState = () => {},
  checked = false,
  text,
  count,
  className = "",
}) => {
  return (
    <FilterCheckboxStyling className={className}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={() => toggleIdState(id)}
      />
      <StyledLabel htmlFor={id}>
        <span>
          <span className="checkblock"></span>
          <span>{text}</span>
        </span>
        {typeof count == "undefined" ? null : <span>{`(${count})`}</span>}
      </StyledLabel>
    </FilterCheckboxStyling>
  )
}

export default FilterCheckbox

// ===============
//     STYLES
// ===============
const FilterCheckboxStyling = styled.div`
  input {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    position: absolute;
  }
  input + label span.checkblock {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 20px;
    margin-bottom: 2px;
    border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
    background: transparent;
    transition: all 0.2s ease-in-out;
  }
  input:checked + label span.checkblock {
    border: 1px solid ${({ theme }) => theme.colors.blue};
    background: ${({ theme }) => theme.colors.blue};
    transition: all 0.2s ease-in-out;
  }
  margin-bottom: 16px;
`
const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
    :last-child {
      padding-left: 0.2rem;
    }
  }
`
